<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDAC53"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 py-8>
            <span
              class="kumbhEBold"
              style="font-size: 24px; border-bottom: 1px solid #000"
              >Contest Application</span
            >
          </v-flex>
          <v-flex xs9 sm6 v-if="applc" pt-6>
            <v-layout wrap justify-start text-left class="kumbhRegular">
              <v-flex xs2 py-2>
                <span><b> Theme</b></span>
              </v-flex>
              <v-flex xs10 py-2>
                <span class="kumbhEBold">{{ applc.selectTheme }}</span>
              </v-flex>
              <v-flex xs2 py-2>
                <span><b> Name </b></span>
              </v-flex>
              <v-flex xs10 py-2>
                <span>{{ applc.firstName }}</span>
              </v-flex>
              <v-flex xs2 py-2>
                <span><b> Email</b></span>
              </v-flex>
              <v-flex xs10 py-2>
                <span>{{ applc.email }}</span>
              </v-flex>

              <v-flex xs2 py-2>
                <span><b> Date </b></span>
              </v-flex>
              <v-flex xs10 py-2>
                <span>{{ DateFormatted(applc.create_date) }}</span>
              </v-flex>

              <v-flex xs2 py-2>
                <span><b> Description</b></span>
              </v-flex>
              <v-flex xs10 py-2>
                <span>{{ applc.description }}</span>
              </v-flex>
              <v-flex xs6 py-2 v-if="applc.ismonthly">
                <span><b> Monthly newsletter subscription </b></span>
              </v-flex>
              <v-flex
                xs4
                py-2
                v-if="applc.ismonthly"
                align-self-center
                text-left
              >
                <v-checkbox
                  v-model="applc.ismonthly"
                  hide-details
                  label=" "
                ></v-checkbox>
              </v-flex>

              <v-flex xs12 py-2>
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs1>
            <v-divider vertical></v-divider>
          </v-flex>
          <v-flex xs2 sm5 v-if="applc">
            <v-layout wrap justify-center v-if="applc.photo" pt-6>
              <v-flex xs12>
                <span class="kumbhEBold" style="font-size: 20px"> Uploaded Image </span>
              </v-flex>
              <v-flex xs12 py-3 v-if="applc.photo">
                <span
                  v-if="
                    applc.photo.split('.').pop() == 'pdf' ||
                    applc.photo.split('.').pop() == 'PDF' ||
                    applc.photo.split('.').pop() == 'xlsx' ||
                    applc.photo.split('.').pop() == 'doc' ||
                    applc.photo.split('.').pop() == 'docx'
                  "
                >
                  <a download :href="baseURL + '/i/' + applc.photo">
                    <v-btn dark color="blue" small> View </v-btn></a
                  ></span
                >
                <span v-else>
                  <v-img
                    @click="(doc = applc.photo), (dialog = true)"
                    :src="baseURL + '/i/' + applc.photo"
                  ></v-img>
                  <br>
                  <a
            @click.prevent="
              downloadItem({
                url: 'https://wtiadmin.in/i/' + applc.photo,
                label: 'Image',
              })
            "
            target="_blank"
            :href="baseURL + '/i/' + doc"
          >
            <v-btn tile small outlined color="primary">
              <span>Download Image</span>
            </v-btn>
          </a>
                  <!-- <v-btn
                    @click="(doc = applc.photo), (dialog = true)"
                    dark
                    color="blue"
                    small
                  >
                    View
                  </v-btn> -->
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- <v-flex xs12 py-16 v-else>
            <span>OOPs!. No Data!</span>
          </v-flex> -->
        </v-layout>
      </v-flex>
    </v-layout>

    <v-dialog persistent v-model="dialog" width="70%">
      <v-card>
        <v-toolbar height="30px" dense dark :color="appColor">
          <v-spacer></v-spacer>
          <v-btn right icon dark @click="(doc = null), (dialog = false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="text-h5 grey lighten-2"> Document </v-card-title>

        <!-- <v-card-text v-if="doc.split('.').pop() == 'pdf'">
          <iframe
            v-if="doc"
            :src="baseURL + '/' + doc"
            height="700px"
            width="900px"
            contain
          >
          </iframe>
        </v-card-text> -->
        <v-card-text v-if="doc">
          <v-img :src="baseURL + '/i/' + doc"></v-img>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <a
            @click.prevent="
              downloadItem({
                url: 'https://wtiadmin.in/i/' + doc,
                label: 'Image',
              })
            "
            target="_blank"
            :href="baseURL + '/i/' + doc"
          >
            <v-btn tile small outlined color="primary">
              <span>Download Image</span>
            </v-btn>
          </a>
          <!-- <v-spacer></v-spacer> -->
          &nbsp;&nbsp;
          <v-btn
            outlined
            tile
            small
            color="red"
            text
            @click="(dialog = false), (doc = null)"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      addEmployeeDialog: false,
      doc: null,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      content: "",
      location: "",
      jobCategory: null,
      categoryList: [],
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      category: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,
      applc: null,
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    async downloadItem({ url, label }) {
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "image/png" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    DateFormatted(params) {
      // moment().format("dddd, MMMM Do YYYY, h:mm:ss a")
      // return params ? moment(params).format("DD-MMMM-Y <br> hh:mm A") : "";

      params = moment(params).subtract(5, "h").subtract(30, "m");
      return params ? moment(params).format("DD-MMMM-Y") : "";
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/quiz/form/get",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.applc = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addAsEmployee() {
      axios({
        url: "/accept/resume",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.showsnackbar = true;
          this.msg = response.data.msg;
          this.$router.go(-1);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
